import React from "react"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const BinsPage = ({ data }) => {
  return (
    <Layout>
      <h1>Bins</h1>
      <div style={{ float: `left`, maxWidth: `800px` }}>
        <h2>Household waste</h2>
        <hr />
        <p>
          Please deposit all household rubbish in the appropriate bins, which
          are clearly labelled, and ensure that the lids are properly closed. Do
          not leave items outside or near the bins as this encourages vermin and
          seagulls and is causing a problem for some residents. Such items will
          not be removed through the weekly collection.{" "}
          <b>
            If the bin nearest to your door is full, please find another one
          </b>
          . Please think of the environment and your fellow residents and
          dispose of your rubbish responsibly! Thank you.
        </p>
        <p>General waste bins are emptied every Sunday, and the Recycling bins every Tuesday.</p>

        <blockquote>
          <ul>
            <li>
              <OutboundLink href="https://webforms.edinburgh.gov.uk/site/portal/request/communal_bin">
                Report a full or overflowing communal bin
              </OutboundLink>
            </li>
          </ul>
        </blockquote>

        <h2>Recycling</h2>
        <hr />
        <p>
          TCVOA are currently working with the council to bring in more bins for
          recycling as we recognise the majority of bins at present are for
          landfill, although there are some Mixed, Paper, Food and Glass
          recycling bins available. Further glass recycling is available in the
          Co-op car park on Dalry Road. This is a process which is dependent on
          council budgets and availability of bins. We ask for your patience
          with this.
        </p>

        <h2>Bulky items</h2>
        <hr />
        <p>
          If you have a bulky item, please contact the council using the link
          below to arrange collection. These items will not be uplifted during
          the normal weekly collections.
        </p>
        <blockquote>
          <ul>
            <li>
              <OutboundLink href="https://www.edinburgh.gov.uk/bulkywaste">
                Request a collection of household bulky waste items
              </OutboundLink>
            </li>
          </ul>
        </blockquote>

        <h2>Pet Fouling</h2>
        <hr />

        <p>
          We all love our pets but no-one likes the mess they can leave behind.
          Please be a responsible pet owner and Bag It and Bin It!
        </p>
        <blockquote>
          <ul>
            <li>
              <OutboundLink href="https://www.edinburgh.gov.uk/dogfouling">
                Report dog fouling
              </OutboundLink>
            </li>
          </ul>
        </blockquote>

        <p>
          The committee are aware of an increase in the instance of dog mess
          fouling the roads and pavements. It is actually an offence not to
          clean up after your dog, subject to an £80 fixed penalty.
        </p>
      </div>
    </Layout>
  )
}

export default BinsPage
